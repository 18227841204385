import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, InputNumber, notification } from "antd";
import React, { useState } from "react";
import { SEMESTERS_COURSES_UPDATE } from "../../../apis/apis";
import { useNavigate } from "react-router-dom";

const EnrollmentThresholds = (props) => {
  const { calendar_id, sem_id, item} = props;
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [enrollmentThresholds, setEnrollmentThresholds] = useState(item?.enrollment_thresholds);
  const [table_list, set_table_list] = useState([]);
  const [table_list_search, set_table_list_search] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "view") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.view
      );
    }
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const UPDATE_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", atob(calendar_id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("course_id", item?.course_id);
    FORM_DATA.append("faculty", item?.faculty == null ? "" : item?.faculty);
    FORM_DATA.append(
      "course_type",
      item?.course_type == null ? "" : item?.course_type
    );
    FORM_DATA.append(
      "enrollment_thresholds",
      enrollmentThresholds == null ? "" : enrollmentThresholds
    );
    FORM_DATA.append("room_id", item?.room_id == null ? "" : item?.room_id);
    FORM_DATA.append("timing", item?.timing == null ? "" : item?.timing);
    FORM_DATA.append("parent", item?.parent);
    // FORM_DATA.append('enrollment_students', item.enrollment_students);
    // FORM_DATA.append('scheduled', item.scheduled);
    // FORM_DATA.append('schedule_status', item.schedule_status);
    FORM_DATA.append("id", item?.schedule_id);
    const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Enrollment Threshold added successfully!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/semester-courses/" + calendar_id + "/" + sem_id);
      set_loader(false);
    } else {
      set_errors(API_RESPONSE?.data?.errors);
    }
  };
  const set_sem_course = () => {
    set_loader(true);
    UPDATE_API();
  };

  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
        <div className="row" style={{paddingTop:"26px",paddingLeft:"10px" }}>
          <InputNumber
            min={1}
            max={60}
            value={enrollmentThresholds}
            onChange={(value) => setEnrollmentThresholds(value)}
          />
          <br/>
          <Button onClick={set_sem_course} style={{background:"#B64141",marginTop:"5px"}} type="primary" size="small">Save</Button>
          </div>
        </>
      )}
    </>
  );
};

export default EnrollmentThresholds;
