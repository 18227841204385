import React, { useEffect, useRef, useState } from "react";
import { Button, Select, Slider } from "antd";
import { useNavigate } from "react-router-dom";

import SisLoader from "../../../../widgets/loader";
import CourseSchedulingAndUtilizationReportSummaryTable from "./advancedCourseSchedulingandUtilizationReportSummaryTable";
import { ADVANCED_COURSE_SCHEDULING_AND_UTILIZATION_REPORT,CALENDARS_LIST, PROGRAMS_LIST, CALENDAR_SCHEDULE_COURSES_SEARCH, FACULTIES_LIST_WITHOUT_PAGINATION, CLASSROOMS_LIST} from "../../../../apis/apis";


const AdvancedCourseSchedulingAndUtilizationReportVisualSection = () => {
  const [loader, set_loader] = useState(false);
  const [academic_year, set_academic_year] = useState(""); 
  const [semester, set_semester] = useState(""); 
  const [program_major, set_program_major] = useState("");
  const [course_id, set_course_id] = useState('');
  // const [classroom_location, set_classroom_location] = useState("");
  const [course_type, set_course_type] = useState("");
  const [instructor, set_instructor] = useState("");
  const [time_slot, set_time_slot] = useState("");
  const [enrollment_capacity, set_enrollment_capacity] = useState(0);
 
  const [table_list, set_table_list] = useState([]);
  const [programme_list, set_programme_list] = useState([]); 
  const [errors, set_errors] = useState([]);
  const [calender_index, set_calender_index] = useState(0);
  const [course_list, set_course_list] = useState([]); 
  const [searchQuery, setSearchQuery] = useState("");

  const [academic_year_list, set_academic_year_list] = useState([]); 
 
  const [sem_list, set_sem_list] = useState([]); 
  const [faculty_list, set_faculty_list] = useState([]);
  const [room_list, set_room_list] = useState([]);
  
  const [classroom_id, set_classroom_id] = useState("");


  const debounceTimeout = useRef(null);
  const [record_found, set_record_found] = useState("");

  
    const LIST_API = async () => {
      set_loader(true);
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
        set_academic_year_list(API_RESPONSE?.data?.calendars);
        // set_initialAcademic_year(API_RESPONSE?.data?.calendars[0]?.id)
        // set_initialAcademic_year(API_RESPONSE?.data?.calendars[0]?.semester[0]?.id)
        set_loader(false);
      } else {
        set_loader(false);
      }
    };
  
    const calender_change_function = (value) => {
      set_semester("");
      const index = academic_year_list.findIndex((item) => item.id === value);
      if (index !== -1) { // Only proceed if a valid academic year is selected
        set_academic_year(value);
        set_calender_index(index);
        set_sem_list(academic_year_list[index]?.semesters || []);  // Ensure semesters exist or use an empty array
      } else {
        // Handle case when custom option (e.g., "Please select academic calendar") is selected
        set_academic_year("");  // Reset academic year
        set_calender_index(-1);  // Reset index
        set_sem_list([]);  // Reset semesters list
      }
    };
  
    const PROGRAMS_LIST_API = async () => {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
      if (API_RESPONSE.data.status) {
        set_programme_list(API_RESPONSE.data.programmes);
      } else {
        set_loader(false);
      }
    };

    const SEARCH_LIST_API = async (UserQuery) => {
      const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
      const FORM_DATA = new FormData();
      FORM_DATA.append('token', USER_DATA.token);
      FORM_DATA.append("search", UserQuery ? UserQuery : "");
      const API_RESPONSE = await CALENDAR_SCHEDULE_COURSES_SEARCH(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
          set_course_list(API_RESPONSE?.data?.courses?.data);

      } else {
      }
  }


    const FACULTY_LIST_API = async () => {
      const FACULTIES_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", FACULTIES_DATA.token);
      const API_RESPONSE = await FACULTIES_LIST_WITHOUT_PAGINATION(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
        set_faculty_list(API_RESPONSE?.data?.faculty);
      }
    };
    const ROOM_LIST_API = async () => {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      const API_RESPONSE = await CLASSROOMS_LIST(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
        set_room_list(API_RESPONSE.data.classrooms);
      }
    };
    const debounce = (callback, delay) => {
      return (...args) => {
          clearTimeout(debounceTimeout.current);
          debounceTimeout.current = setTimeout(() => {
              callback(...args);
          }, delay);
      };
  };
  
  const handleSearch = debounce((query) => {
    SEARCH_LIST_API(query)
  }, 800);

  
  
   useEffect(() => {
      LIST_API();
      PROGRAMS_LIST_API();
      ROOM_LIST_API();
      FACULTY_LIST_API();
      SEARCH_LIST_API();
    }, [academic_year]);

  const GENERATE_COURSE_SCHEDULING_REPORT = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", academic_year);
    FORM_DATA.append("semester_id", semester);
    FORM_DATA.append("program_major", program_major);
    FORM_DATA.append("course_id", (course_id == null || course_id == undefined) ? "" : course_id);
    // FORM_DATA.append("classroom location", classroom_location);
    FORM_DATA.append("classroom_id", classroom_id);
    FORM_DATA.append("course_type", course_type);
    FORM_DATA.append("instructor", instructor);
    FORM_DATA.append("time_slot", time_slot);
    FORM_DATA.append("enrollment_capacity", enrollment_capacity);



   

    const API_RESPONSE = await ADVANCED_COURSE_SCHEDULING_AND_UTILIZATION_REPORT(FORM_DATA);
  if(API_RESPONSE?.data?.status)
    {
      if(API_RESPONSE?.data?.courses?.length > 0)
      {
        set_table_list(API_RESPONSE?.data?.courses);
      }
      else
      {
        set_record_found("No Record Found...")
        set_table_list("");
      }
      set_errors("");
      set_loader(false);
    }
    else{
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  }

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="theme-content-left-head">
            <h2>Course Scheduling and Utilization Reports</h2>
            <br />
            <p>Analyze course offerings and classroom utilization.</p>
          </div>
          <br />
          <hr />
          <br />
          <h3 style={{ fontSize: "18px", fontWeight: "600", color: "#4b4b4b" }}>
            Filters
          </h3>
          <br />

          {/* Academic Year, Semester and Program Section */}
          <div className="row">
                      <div className="col-3">
                        <div className="input-box" style={{ width: "100%" }}>
                          <label>
                            Academic Year<i style={{ color: "red" }}>*</i>
                          </label>
                          <br />
                          <Select
                            placeholder="--Select Academic Year--"
                            style={{ width: "100%", height: "40px" }}
                            onChange={calender_change_function}
                            value={
                              academic_year ? parseInt(academic_year) : academic_year
                            }
                            options={[
                              { value: "", label: "Please select academic calendar" }, 
                              ...academic_year_list.map((item) => ({
                                value: item.id,
                                calender_id: item.id,
                                label: item.title,
                              })),
                            ]}
                          />
                          {errors?.calendar_id && (
                            <>
                              <span style={{ color: "red" }}>
                                The Academic Year field is required.
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input-box" style={{ width: "100%" }}>
                          <label>
                            Semester<i style={{ color: "red" }}>*</i>
                          </label>
                          <br />
          
                          <Select
                            placeholder="--Select Semester--"
                            style={{ width: "100%", height: "40px" }}
                            disabled={Boolean ? !academic_year : academic_year} // Disable until a academic year is selected
                            onChange={(value) => {
                              set_semester(value);
                            }}
                            value={semester}
                            options={[
                              { value: "", label: "Please select semester" }, // Static custom option
                              ...sem_list.map((item) => ({
                                value: item?.id,
                                label: item?.title,
                              })),
                            ]}
                          />
          
                          {errors?.semester_id && (
                            <>
                              <span style={{ color: "red" }}>
                                The semester field is required.
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-box">
                          <label>
                            Program / Major<i style={{ color: "red" }}>*</i>
                          </label>
                          <Select
                            value={program_major}
                            placeholder="--Select programme--"
                            style={{ width: "100%", height: "40px" }}
                            onChange={(value) => set_program_major(value)}
                          >
                             <Select.Option value="">
                              Please select program
                            </Select.Option>
                            {programme_list.map((item) => {
                              if (item.status === 1 && item.soft_delete === 0) {
                                return (
                                  <>
                                    <Select.Option value={item.id}>
                                      {item.title}
                                    </Select.Option>
                                  </>
                                );
                              }
                            })}
                          </Select>
                          {errors?.program_major && (
                            <>
                              <span style={{ color: "red" }}>
                                The Program field is required.
                              </span>
                            </>
                )}
              </div>
            </div>
          </div>

          {/* Course Filters Section */}
          <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>Course</label>
                <br />
               <Select
                           placeholder="Select Courses"
                           allowClear
                            showSearch
                           onSearch={handleSearch}
                           style={{ width: "100%",  height:"40px" }}
                           filterOption={(inputValue, option) => {
                            const optionCode = option.code ? option.code.toLowerCase() : "";
                            const optionTitle = option.title ? option.title.toLowerCase() : "";
                            return optionCode.includes(inputValue.toLowerCase()) || optionTitle.includes(inputValue.toLowerCase());
                          }}
                           onChange={(value) => set_course_id(value)}
                           value={course_id}
                           options={course_list?.map((item) => ({
                             value: item.id,
                             title: item.title,
                             label: item.title + "(" + item.code + ")",
                             code: item.code
                           }))}
                         ></Select>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Classroom Location</label>
                <Select
                  placeholder="--Select Classroom--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_classroom_id(value)}
                  value={classroom_id}
                >
                  <Select.Option value="">
                    Please Select Classroom Location
                   </Select.Option>
                  {room_list?.length > 0 && <>
                                <Select.Option value="To be allocated">To be allocated</Select.Option>
                                    {room_list?.map(itemv =>(
                                        <><Select.Option value={itemv.id}>{itemv.room_name} <span style={{float:"right"}}></span></Select.Option></>
                                    ))}
                            </>}
                </Select>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Course Type</label>
                <Select
                  placeholder="--Select Course Type--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_course_type(value)}
                  value={course_type}
                >
                  <Select.Option value="">
                    Please select Course Type
                   </Select.Option>
                  <Select.Option value="online">Online</Select.Option>
                  <Select.Option value="in-person">In-Person</Select.Option>
                  <Select.Option value="hybrid">Hybrid</Select.Option>
                </Select>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box">
                <label>Instructor</label>
                <Select
                  placeholder="--Select Instructor--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_instructor(value)}
                  value={instructor}
                >
                  <Select.Option value="">
                    Please select Instructor
                   </Select.Option>
                 {faculty_list.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                    {item?.honorific && item?.honorific}{" "}{item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {/* Time Slot, Enrollment Capacity Section */}
          <div className="row">
            <div className="col-3">
              <div className="input-box">
                <label>Time Slot</label>
                <Select
                  placeholder="--Select Time Slot--"
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => set_time_slot(value)}
                  value={time_slot}
                >
                   <Select.Option value="">
                    Please select Time Slot
                   </Select.Option>
                  <Select.Option value="morning">Morning (9 a.m. - 11:45 a.m.)</Select.Option>
                  <Select.Option value="afternoon">Afternoon (12:30 p.m. - 3:15 p.m.)</Select.Option>
                  <Select.Option value="evening">Evening (3:30 p.m. - 6:15 p.m.)</Select.Option>
                </Select>
              </div>
            </div>
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>Enrollment Capacity</label>
                <br />
                <Slider
                  min={0}
                  max={100}
                  onChange={(value) => set_enrollment_capacity(value)}
                  value={enrollment_capacity}
                  step={5}
                />
              </div>
            </div>
          </div>

          {/* Generate Report Button */}
          <br />
          <Button
            onClick={GENERATE_COURSE_SCHEDULING_REPORT}
            style={{ backgroundColor: "#4b4b4b", color: "#ffffff", width: "156px", height: "40px" }}
            type="primary"
          >
            Generate Report
          </Button>
          <br />
          <br />
          {table_list?.length > 0 ? (
            <CourseSchedulingAndUtilizationReportSummaryTable
            table_list={table_list}
            academic_year = {academic_year}
            semester= {semester}
            program_major= {program_major}
            course_id= {course_id}
            classroom_id= {classroom_id}
            // classroom_location = {classroom_location}
            course_type = {course_type}
            instructor  = {instructor}
            time_slot= {time_slot}
            enrollment_capacity = {enrollment_capacity}
            
            

              />
          )
          :
          <p style={{color:"red",textAlign:"center", marginTop:"20px"}}>{record_found}</p>
        }
        </>
      )}
    </>
  );
};

export default AdvancedCourseSchedulingAndUtilizationReportVisualSection;


