import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Input, Radio, Spin, notification } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CloseOutlined,
  CloudUploadOutlined,
  FrownOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  ADD_COURSE,
  ADD_PROGRAM,
  EDIT_PROGRAM,
  SIS_STORAGE,
  UPDATE_PROGRAM,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";
import { TINY_KEY } from "../../../config/config";
const SisProgrammeEdit = (props) => {
  const { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [page_loader, set_page_loader] = useState(true);
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);

  // Form State
  const [certificate_programs, set_certificate_programs] = useState(1);
  const [title, set_title] = useState("");
  const [unit, set_unit] = useState("");
  const [image, set_image] = useState("");
  const [programme_code, set_programme_code] = useState("");
  const [description, set_description] = useState("");
  const [program_concentration, set_program_concentration] = useState("");
  const [url_image, set_url_image] = useState("");
  const programImage = useRef(null);
  const [showProgramImageAlert, setShowProgramImageAlert] = useState(false);

  const EDIT_PROGRAM_VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await EDIT_PROGRAM(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE.data.status) {
      set_title(EDIT_ROLE_API_RESPONSE?.data?.programme?.title);
      if (EDIT_ROLE_API_RESPONSE?.data?.programme?.programme_code === "null") {
        set_programme_code("");
      } else {
        set_programme_code(
          EDIT_ROLE_API_RESPONSE?.data?.programme?.programme_code
        );
      }
      set_program_concentration(
        EDIT_ROLE_API_RESPONSE?.data?.programme?.program_concentration
      );
      set_unit(EDIT_ROLE_API_RESPONSE?.data?.programme?.unit);
      set_certificate_programs(
        EDIT_ROLE_API_RESPONSE?.data?.programme?.certificate_programs
      );
      set_description(EDIT_ROLE_API_RESPONSE?.data?.programme?.description);
      set_image(EDIT_ROLE_API_RESPONSE?.data?.programme?.image);
      set_page_loader(false);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
      set_page_loader(false);
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    
    if (files && files.length > 0) {
        const file = files[0];
        const maxSize = 2 * 1024 * 1024;

        if ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < maxSize) {
            setShowProgramImageAlert(false);
            set_url_image(file);
        } else {
            set_url_image("");
            setShowProgramImageAlert(true);
            programImage.current.value = "";
        }
    }
};


  // const handleImageChange = (e) => {
  //   const files = e.target.files;
  //   if (
  //     (files[0].type === "image/jpeg" ||
  //       files[0].type === "image/jpg" ||
  //       files[0].type === "image/png") &&
  //     files[0].size < 2000001
  //   ) {
  //     set_url_image(files[0]);
  //   } else {
  //     notification.open({
  //       message: "Not Allowed",
  //       description:
  //         "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
  //       icon: <SmileOutlined style={{ color: "red" }} />,
  //     });
  //   }
  // };
  const handleRemoveImage = (e) => {
    set_url_image("");
     programImage.current.value = "";
  };

  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("title", title);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("image", image);
    FORM_DATA.append("programme_code", programme_code);
    FORM_DATA.append("programme_id", atob(id));
    FORM_DATA.append("unit", unit);
    FORM_DATA.append("certificate_programs", certificate_programs);
    FORM_DATA.append("program_concentration", program_concentration);
    const API_RESPONSE = await UPDATE_PROGRAM(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Programme Basic Information Successfully Updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/section/" + btoa(API_RESPONSE.data.id));
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  useEffect(() => {
    EDIT_PROGRAM_VIEW_API();
  }, []);
  return (
    <>
      {page_loader ? (
        <>
          <SisLoader />
        </>
      ) : (
        <>
          <div className="theme-content-head">
            <div className="theme-content-left-head">
              <h3>
                Edit Programme{" "}
                <Button
                style={{backgroundColor:"#B64141"}}
                  type="primary"
                  size="small"
                  onClick={() => navigate("/programmes-list")}
                >
                  Back
                </Button>
              </h3>
            </div>
            <div className="theme-content-right-head"></div>
          </div>
          <div className="form-step-box">
            <div className={"form-step running"}>
              <div className="form-step-line"></div>
              <span>1</span>
              <h3>Basic Information </h3>
            </div>
            <div className={"form-step"}>
              <span>2</span>
              <h3>Programme Section</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="input-box">
                <label htmlFor="name">
                  Programme Title<i style={{ color: "red" }}>*</i>
                </label>
                <Input
                  value={title}
                  placeholder="Title"
                  id="title"
                  onChange={(e) => set_title(e.target.value)}
                />
                {errors?.title && (
                  <>
                    <span style={{ color: "red" }}>{errors?.title[0]}</span>
                  </>
                )}
              </div>
              <div className="input-box">
                <label htmlFor="name">
                  Programme Code<i style={{ color: "red" }}>*</i>
                </label>
                <Input
                  value={programme_code}
                  placeholder="Title"
                  id="title"
                  onChange={(e) => set_programme_code(e.target.value)}
                />
                {errors?.programme_code && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.programme_code[0]}
                    </span>
                  </>
                )}
              </div>
              <div className="input-box">
                <label htmlFor="name">
                  Programme Description<i style={{ color: "red" }}>*</i>
                </label>
                <Editor
                  apiKey={TINY_KEY}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={description}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins:
                      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools",
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                {errors?.description && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.description[0]}
                    </span>
                  </>
                )}
              </div>
              <div className="input-box">
                <label htmlFor="Units">
                  Programme Units<i style={{ color: "red" }}>*</i>
                </label>
                <Input
                  value={unit}
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Programme Units"
                  id="Units"
                  onChange={(e) => set_unit(e.target.value)}
                />
                {errors?.unit && (
                  <>
                    <span style={{ color: "red" }}>{errors?.unit[0]}</span>
                  </>
                )}
              </div>
              {/* <div className='input-box'>
                            <label htmlFor="Units">Program Concentration<i style={{ color: "red" }}>*</i></label>
                            <Input value={program_concentration} placeholder="Program Concentration" id='program_concentration' onChange={(e) => set_program_concentration(e.target.value)} />
                            {errors?.program_concentration && <><span style={{ color: "red" }}>{errors?.program_concentration[0]}</span></>}
                        </div> */}
              <div className="input-box">
                <label htmlFor="Units">
                  Program Type<i style={{ color: "red" }}>*</i>
                </label>
              </div>
              <div>
                <Radio.Group
                  onChange={(e) => set_certificate_programs(e.target.value)}
                  value={certificate_programs}
                >
                  <Radio value={1}>Under Graduate</Radio>
                  <Radio value={2}>Graduate</Radio>
                  <Radio value={3}>Doctorate</Radio>
                  <Radio value={4}>Certificate Programs</Radio>
                </Radio.Group>
                <br></br>
                {errors?.certificate_programs && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.certificate_programs[0]}
                    </span>
                  </>
                )}
              </div>
              <div className="input-box" style={{ position: "relative" }}>
                <label htmlFor="address">Programme Photo</label>
                <br></br>
                <label className="upload-box">
                  {" "}
                  <input
                   ref={programImage}
                    type="file"
                    id="upload-images"
                    accept="image/*"
                    style={{ height: "0px", overflow: "hidden" }}
                    onChange={handleImageChange}
                  />{" "}
                  <CloudUploadOutlined /> Upload Image
                </label>
                {showProgramImageAlert ?
                            <>
                            <Alert
                            message={<span><FrownOutlined style={{ color: 'red',fontSize:"25px" }} /> Error</span>}
                            description="Image file format must be .jpg, .jpeg or .png and the image size should not exceed 2MB."
                            type="error"
                            //   showIcon
                              closable
                              onClose={() => setShowProgramImageAlert(false)}
                            />
                            <br/>
                            </>
                          :
                          <>
                {url_image ? (
                  <>
                    <div
                      className="image-box"
                      style={{
                        backgroundImage:
                          "url(" + URL.createObjectURL(url_image) + ")",
                      }}
                    ></div>
                    <button
                      className="image-remove"
                      onClick={() => handleRemoveImage()}
                    >
                      <CloseOutlined />
                    </button>
                  </>
                ) : (
                  <>
                    {image && (
                      <>
                        <div
                          className="image-box"
                          style={{
                            backgroundImage:
                              "url(" +
                              SIS_STORAGE +
                              "/programmes/" +
                              image +
                              ")",
                          }}
                        ></div>
                      </>
                    )}
                  </>
                )}

                {errors?.image && (
                  <>
                    <span style={{ color: "red" }}>{errors?.image[0]}</span>
                  </>
                )}
                </>
                }
              </div>

              <div className="input-box" style={{ marginTop: "20px" }}>
                {loader ? (
                  <>
                    <Button type="primary" className='lusisbtn' style={{width:"156px"}}>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Update & Next
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type="primary" onClick={UPDATE_API} className='lusisbtn' style={{width:"156px"}}>
                      Update & Next
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SisProgrammeEdit;
