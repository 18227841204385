import React from 'react'
import PreviousGradeTable from './previousGradeTable'

const PreviousSemesterWiseGradeView = ({item}) => {
  return (
    <>
    {
      (item?.transferred_course?.length > 0 ||  item?.university_course?.length > 0) && <>
      {
        item?.transferred_course?.length > 0 ? <>
          <br></br>
          <div className='first-letter-capitalize' style={{fontWeight:"700"}}>Transferred Course</div>
          <PreviousGradeTable transferred_course_item={item?.transferred_course}/>
        </>
          : <></>
      }
      {
        item?.university_course?.length > 0 ? <>
         <br></br>
        <h3 style={{ color: "#3B82F6" }}>{item?.semester?.title}</h3>
          <br></br>
          <h3 className='first-letter-capitalize' style={{ color: "#3B82F6" }}>Lincoln University</h3>
          <PreviousGradeTable university_course_item={item?.university_course}/>
        </>
          : <></>
      }
      {/* </div> */}
      </>
    }
      
    </>
  )
}

export default PreviousSemesterWiseGradeView