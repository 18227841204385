import { LoadingOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Input, notification } from 'antd'
import React, { useState } from 'react'
import { STUDENT_SERVICE_ADD_UPDATE } from '../../apis/apis';

const HealthInsuranceComment = ({item,active_calender_id,active_sem_id,searchQuery,currentPage,SEARCH_LIST_API,REGISTER_STUDENT_LIST_API,table_list}) => {
const [loader, set_loader] = useState(false);
const [errors, set_errors] = useState([]);
const [comment, set_comment_data] = useState("");

     const ADD_API = async (health_insurance_status, comment, student_id) => {
         set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("acad_calender_id", active_calender_id);
        FORM_DATA.append("semester_id", active_sem_id);
        FORM_DATA.append("student_id", student_id);
        FORM_DATA.append("student_status", "Ready for payment");
        FORM_DATA.append("table_list", JSON.stringify(table_list));
        FORM_DATA.append("health_insurance_status", health_insurance_status);
        FORM_DATA.append("comment", (comment == null || comment == "null") ? "" : comment) ;
    
        const API_RESPONSE = await STUDENT_SERVICE_ADD_UPDATE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
          notification.open({
            message: "Success!!",
            description: "Student Semester Health Insurance update successfully .",
            icon: <SmileOutlined style={{ color: "green" }} />,
          });
          if(searchQuery)
            {
              SEARCH_LIST_API(searchQuery);
            }
            else{
              REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,currentPage)
            }
          // REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,currentPage);
          // navigate('/health-insurance/' + calender_id + '/' + sem_id)
        } else {
          set_errors(API_RESPONSE?.data?.errors);
          set_loader(false);
        }
      };

  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
        <div className="row" style={{paddingTop:"26px",paddingLeft:"10px" }}>
        <Input
            defaultValue={item?.enrollment_details?.comment}
            placeholder=" comment....."
            onChange={(e) => set_comment_data(e.target.value)}
            />
        <br/>
          <Button onClick={()=> ADD_API(item?.enrollment_details?.health_insurance_status,comment,item.student_id)}
         style={{background:"#B64141",marginTop:"5px"}} type="primary" size="small">Save</Button>
          </div>
        </>
      )}
    </>
  )
}

export default HealthInsuranceComment;