import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, notification, Input, Tooltip } from 'antd';
import './style.css';
import { TableColumnsType, TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, FilePdfOutlined, CloudDownloadOutlined, EditOutlined, SmileOutlined, StopOutlined, FundViewOutlined, EyeFilled, PlusOutlined } from '@ant-design/icons';
import SisLoader from '../../widgets/loader';
import { COURSES_LIST, DELETE_COURSE, DELETE_PROGRAM, PROGRAMS_LIST, STATUS_COURSE, STATUS_PROGRAM } from '../../apis/apis';
import { BACKEND_URL } from '../../config/config';

const ProgrammeList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    // Permissions state manage 
    const [all_permissions, set_all_permissions] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
            return true;
        }
        var check_permissions = [];
        if (type === 'view') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
        }
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }

        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    // Form State
    const [title, set_title] = useState('');
    const [table_list, set_table_list] = useState([]);

    // Search
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = table_list?.filter(item => {
            const title = item.title?.toLowerCase() || '';
            const unit = item.unit?.toString().toLowerCase() || '';
            const programmeCode = item.programme_code?.toLowerCase() || '';
    
            return (
                title.includes(query.toLowerCase()) ||
                unit.includes(query.toLowerCase()) ||
                programmeCode.includes(query.toLowerCase())
            );
        });
        if (query === '') {
            setFilteredData(table_list);
        } else {
            setFilteredData(filtered);
        }
    };

    // Status
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_PROGRAM(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            if (API_RESPONSE?.data?.type === 'yes') {
                notification.open({
                    message: 'Success!!',
                    description: API_RESPONSE?.data?.message,
                    icon: <SmileOutlined style={{ color: 'green' }} />,
                });
            } else {
                notification.open({
                    message: 'Note!!',
                    description: API_RESPONSE?.data?.message,
                    icon: <SmileOutlined style={{ color: 'yellow' }} />,
                });
            }
            PROGRAMS_LIST_API();
            //  navigate('/programmes-list')
            
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false)
        }
    }
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_PROGRAM(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: API_RESPONSE?.data?.type ? 'Success!!' : "Failed",
                description: API_RESPONSE?.data?.message,
                icon: <SmileOutlined style={API_RESPONSE?.data?.type ? { color: 'green' } : { color: 'red' }} />,
            });
            PROGRAMS_LIST_API();
            // navigate('/programmes-list')
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false)
        }
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Programe Code',
            dataIndex: 'programme_code',
            key: 'programme_code',
            sorter: (a, b) => a.programme_code.localeCompare(b.unit)
        },

        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Units',
            dataIndex: 'unit',
            key: 'unit',
            sorter: (a, b) => a.unit.localeCompare(b.unit),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <span className="table-status-activate">Active</span>
                    );
                } else {
                    return (
                        <span className="table-status-deactivate">Inactive</span>
                    );
                }
            }
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                      {check_permissions('sis_programmes', 'status') &&  
                        <Popconfirm
                            title="Change Status"
                            description={
                                record.status === 1
                                    ? "Are you sure to change status programme Inactive?"
                                    : "Are you sure to change status programme Active?"
                            }
                            onConfirm={() => {
                                STATUS_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" style={{ backgroundColor: "#888888", marginRight: "5px" }}><StopOutlined /></Button>
                        </Popconfirm> }

                        {check_permissions('sis_programmes', 'update') && <Button type="primary" size="small" onClick={() => navigate('/edit-programme/' + btoa(record.id))} style={{ marginRight: "5px" }}><EditOutlined /></Button> }

                        {check_permissions('sis_programmes', 'delete') && <Popconfirm
                            title="Change Status"
                            description="Are you sure to delete this programme?"
                            onConfirm={() => {
                                DELETE_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" danger><DeleteOutlined /></Button>
                        </Popconfirm> }
                        {check_permissions('sis_programmes', 'view') &&
                        <Button type="primary" size="small" onClick={() => navigate('/section-view/' + btoa(record.id))} style={{ marginLeft: "5px" }}><EyeFilled /></Button>}
                    </>
                )
            }

        },
    ];

    const PROGRAMS_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE?.data?.programmes);
            setFilteredData(API_RESPONSE?.data?.programmes);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        PROGRAMS_LIST_API();
    }, []);


    return (
        <>
         {loader ? <SisLoader /> :<>
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Programmes List</h3>
                </div>
                <div className='theme-content-right-head'>
                {check_permissions('sis_programmes', 'create') &&   <Button type='primary' className='lusisbtn' style={{width:"177px"}} onClick={() => navigate('/add-programme')}><PlusOutlined/> Add Programme</Button> }

                    <Tooltip title="Download Program List CSV">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-programmes/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                    </Tooltip>

                    {/* PDF Download button  */}
                    <Tooltip title="Download Program List PDF">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-programmes-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
            </div>
            <div className='theme-content-head'>
                <div className='input-box'>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    {errors?.code && <span style={{ color: "red" }}>{errors?.code[0]}</span>}
                </div>
            </div>
                <Table columns={columns} dataSource={filteredData} />
        </div>
        </>}
        </>
    );
};

export default ProgrammeList;