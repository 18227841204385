import React, { useEffect, useState } from 'react'
import SisLoader from '../../../widgets/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { STUDENT_ACADEMIC_HISTORY_LIST } from '../../../apis/apis';

const StudentAcademicHistotyListing = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const[table_list, set_table_list] = useState([]);
    
    const check_permissions = (module_name, type) => {
      const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
      if(localStorgeData.role === 2074){
          return true;
      }
      var check_permissions = [];
      if (type === 'view') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
    }
      if (type === 'create') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
      }
      if (type === 'update') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
      }
      if (type === 'status') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
      }
      if (type === 'delete') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
      }
  
      if (check_permissions.length > 0) {
          return true;
      } else {
          return false;
      }
  }

  const VIEW_STUDENT_ACADEMIC_HISTORY_LIST = async() => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('student_id', atob(id));
    const API_RESPONSE = await STUDENT_ACADEMIC_HISTORY_LIST(FORM_DATA);
    if(API_RESPONSE?.data?.status)
    {
      set_table_list(API_RESPONSE?.data?.programmes);
      set_loader(false);
    }
  }
 useEffect(() => {
  VIEW_STUDENT_ACADEMIC_HISTORY_LIST();
    }, []);

  

   const column = [

    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Program Id",
      dataIndex: "program_id",
      key: "program_id",
    },
    {
      title: "Program Title",
      dataIndex: "programme_title",
      key: "programme_title",
    },
    {
      title: "Program Status",
      dataIndex: "programme_status",
      key: "programme_status",
    },
    {
      title: 'Action',
      key: 'Action',
      render: (text, record) => {
          return (
              <>
                  <Button type="primary" size="small" onClick={() => navigate('/students-academic-history-details/'+ id + "/"+ btoa(record.id))} style={{ marginLeft: "5px" }}><EyeFilled /></Button>
              </>
          )
      }

  },
   ]



  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
         <div className="sis-tab-menu">
          {check_permissions('enrollments', 'view') &&
            <span onClick={() => navigate("/student-detail/" +id)} >Profile</span>}
             {check_permissions('attendances', 'view') &&
            <span onClick={() => navigate("/student-academic-detail/" + id)} >Academic</span>}
             {check_permissions('students_grades', 'view') &&
            <span onClick={() => navigate("/students-grade-detail/" + id)} >Grades</span>}
             {check_permissions('students_grades', 'view') &&
            <span className="active" onClick={() => navigate("/students-academic-history/" + id)} >History</span>}
          </div>
          {table_list?.length > 0 ?
          <Table columns={column} dataSource={table_list} pagination={false} />
          :
          <div style={{textAlign:"center" , paddingTop:"50px"}}>
            <p style={{color:"red"}}>No Academic History Found.</p>
          </div>
}
        </>
      )}
      </>
  )
}

export default StudentAcademicHistotyListing;