import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Pagination,
  Popconfirm,
  Table,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
  StopOutlined,
} from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import {
  ENROLLMENTS_LIST,
  DELETE_ENROLLMENT,
  STATUS_ENROLLMENT,
  STUDENTS_ENROLLMENTS_LIST,
  STUDENTS_PROGRAM_REGISTRATION_LIST,
  PROGRAMS_LIST,
  STUDENTS_PROGRAM_REGISTRATION_LISTING_SEARCH,
} from "../../apis/apis";

const EnrollmentsList = () => {
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [student_id, set_student_id] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, set_last_page] = useState(1);
  const [total, set_total] = useState("");
  const [program_intended_title, set_program_intended_title] = useState("");
  const [per_page, set_per_page] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  // Permissions state manage
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const [programme_list, set_programme_list] = useState([]);
  const [search_error, set_search_error] = useState("")
  const debounceTimeout = useRef(null);
  const errorTimeoutRef = useRef(null);

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  
  const findProgramIntendedTitle = (intended) => {
    const program = programme_list?.find((item) => item?.id === intended);
    return program?.title || "-";
  };

  // Form State
  const [table_list, set_table_list] = useState([]);
  const STATUS_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await STATUS_ENROLLMENT(FORM_DATA);
    console.log("hi22", API_RESPONSE.data.status);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrollment status Successfully changed.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/enrollments-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await DELETE_ENROLLMENT(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrollment Successfully deleted.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/enrollments-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const columns = [
    {
      title: "Program Regn Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Student Id",
      dataIndex: "student_id",
      key: "student_id",
      sorter: (a, b) => a?.student_id - b?.student_id,
    },
    // {
    //   title: "Application ID",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <span>{record?.application_no ? record?.application_no : "-"}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Program Title",
      render: (text, record) => {
        return (
          <>
            <span>
              {record?.registered_program?.programme_title
                ? record?.registered_program?.programme_title
                : record?.programme_intended
                ? findProgramIntendedTitle(record?.programme_intended)
                : "-"}
            </span>
          </>
        );
      },
    },
    {
      title: "Student Details",
      render: (text, record) => {
        return (
          <>
            <span>
              {record.first_name}{" "}
              {record.middle_name != (null || "null") && record.middle_name}{" "}
              {record.last_name}
            </span>
            {/* <span><b>Email: </b>{record.primary_email} </span><br></br>
                        <span><b>Phone: </b>{record.phone} </span> */}
          </>
        );
      },
    },
    {
      title: "Student Status",
      render: (text, record) => {
        if (record.student_status == 1) {
          return "Ready for Programme Registration";
        }
        if (record.student_status == 2) {
          return "Enrolled";
        }
      },
    },
    {
      title: "Modify",
      key: "modify",
      render: (record) => {
        return (
          <>
            {(check_permissions(
              "students_programmes_registrations",
              "create"
            ) ||
              check_permissions(
                "students_programmes_registrations",
                "update"
              )) && (
              <Button
                style={{ background: "#b64141" }}
                type="primary"
                onClick={() => navigate("/add-enrollment/" + btoa(record?.id))}
              >
                Register For Program
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE?.data?.programmes);
    }
  };

  // Search
  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);
  // };

  const debounce = (callback, delay) => {
    return (...args) => {
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

  const handleSearch = debounce((query) => {
    if(query != "")
    {
      SEARCH_LIST_API(query);
    }
    else{
      LIST_API(currentPage)
    }
  }, 800);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    if (value === "") {
      set_search_error("");
      handleSearch(value);
      return;
    }
    if (value.length < 3) {
      errorTimeoutRef.current = setTimeout(() => {
      set_search_error("Please enter at least 3 characters.")
    }, 800);
      return;
    }
    set_search_error("");
    handleSearch(value);
  };

  const LIST_API = async (UserSearchPage) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("page",UserSearchPage ? UserSearchPage : currentPage);
    const API_RESPONSE = await STUDENTS_PROGRAM_REGISTRATION_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      console.log("API_RESPONSE", API_RESPONSE);
      set_table_list(API_RESPONSE?.data?.enrollments?.data);
      setFilteredData(API_RESPONSE?.data?.enrollments?.data);
      //set_student_id(API_RESPONSE?.data?.enrollments?.student_id)
      setCurrentPage(API_RESPONSE?.data?.enrollments?.current_page);
      set_last_page(API_RESPONSE?.data?.enrollments?.last_page);
      set_total(API_RESPONSE?.data?.enrollments?.total);
      set_per_page(API_RESPONSE?.data?.enrollments?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    PROGRAMS_LIST_API();
    LIST_API();
  },[])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setDebouncedQuery(searchQuery);
  //     SEARCH_LIST_API(searchQuery);
  //   }, 600);

  //   return () => clearTimeout(timer);
  // }, [searchQuery]);

  const pagination_changes = (UserSearchPage) => {
    setCurrentPage(UserSearchPage);
    if(searchQuery == "")
    {
      LIST_API(UserSearchPage);
    }
    else{
      SEARCH_LIST_API(searchQuery)
    }
    
  };

  const SEARCH_LIST_API = async (UserQuery) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("search", UserQuery);
    const API_RESPONSE = await STUDENTS_PROGRAM_REGISTRATION_LISTING_SEARCH(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      console.log("API_RESPONSE", API_RESPONSE);
      set_table_list(API_RESPONSE?.data?.enrollments?.data);
      setFilteredData(API_RESPONSE?.data?.enrollments?.data);
      //set_student_id(API_RESPONSE?.data?.enrollments?.student_id)
      setCurrentPage(API_RESPONSE?.data?.enrollments?.current_page);
      set_last_page(API_RESPONSE?.data?.enrollments?.last_page);
      set_total(API_RESPONSE?.data?.enrollments?.total);
      set_per_page(API_RESPONSE?.data?.enrollments?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3>Program Registrations List</h3>
              </div>
              {/* <div className='theme-content-right-head'>
                    <Button type='primary' onClick={() => navigate('/add-enrollment')}>Register Student in Program</Button>
                </div> */}
            </div>
            <div className="theme-content-head">
              <div className="input-box">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={false}
            />
            <div style={{ float: "right", marginTop: "20px" }}>
              <Pagination
                onChange={pagination_changes}
                defaultCurrent={parseInt(currentPage)}
                total={parseInt(total)}
                pageSize={parseInt(per_page)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EnrollmentsList;
