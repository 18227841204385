

import { Button, DatePicker, Modal, notification, Select, Spin } from 'antd';
import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SisLoader from '../../../widgets/loader';
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_STUDENT_NEW_PROGRAM } from '../../../apis/apis';

const AddNewProgramModal = ({addNewProgramModal,setAddNewProgramModal,programme_list,entering_year_list,set_page_loader,page_loader}) => {

  const [add_program_loader, set_add_program_loader] = useState(false);
  const dateFormat = "MM-DD-YYYY";
   const navigate = useNavigate();
  const { id } = useParams();
  const [programme_intended, set_programme_intended] = useState("");
  const [selected_program_concentration, set_selected_program_concentration] = useState("");
  const [errors, set_errors] = useState([]);
  const [entering_year, set_entering_year] = useState("");
  const [confer_date, set_confer_date] = useState("");
  const [entering_class, set_entering_class] = useState("");
  const [programme_status, set_programme_status] = useState("");
  const [academic_standing, set_academic_standing] = useState("");
  const [matriculation_semester, set_matriculation_semester] = useState("");
  const [matriculation_date, set_matriculation_date] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [student_type, set_student_type] = useState("");
  const [programme_id, set_programme_id] = useState("");
  const [program_concentration, set_program_concentration] = useState("");
  
  // const [programme_list, set_programme_list] = useState([]);
  const [sem_list, set_sem_list] = useState([]);
  // const [entering_year_list, set_entering_year_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0);


  const calender_change_function = (value) => {
    set_matriculation_semester("");
    const index = entering_year_list.findIndex((item) => item.id === value);
    set_entering_year(value);
    set_calender_index(index);
    set_sem_list(entering_year_list[index].semesters);
    // entering_year_list[index].semesters.map((item)=>{
    //   console.log(item.title);
    // })
  };

  const program_change_function = (value) =>
  {
    set_selected_program_concentration("");
    const index = programme_list.findIndex((item) => item.id === value);
    set_programme_intended(value);
    set_program_concentration(programme_list[index].program_concentration_array);
  }

  const ADD_API = async() => {
    set_add_program_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("program_id", programme_intended);
    FORM_DATA.append("entering_year", entering_year);
    FORM_DATA.append("matriculation_semester", matriculation_semester);
    FORM_DATA.append("matriculation_date", matriculation_date);
    FORM_DATA.append("confer_date", confer_date);
    FORM_DATA.append("programme_status", (programme_status == "null" || programme_status == null) ? " " : programme_status);
    FORM_DATA.append("program_concentration", selected_program_concentration !== 'null' ? selected_program_concentration : '');
    FORM_DATA.append("student_type", (student_type == "null" || student_type == null) ? " " : student_type);
    FORM_DATA.append("entering_classification", (entering_classification == "null" || entering_classification == null) ? " " : entering_classification );
    FORM_DATA.append("academic_standing",academic_standing);
    const API_RESPONSE = await ADD_STUDENT_NEW_PROGRAM(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrolled in Program Successfully",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      // Navigate("/enrollments-list");
      set_add_program_loader(false)
      set_page_loader(page_loader ? false : true);
     //  window.location = '/add-enrollment/'+id;
      setAddNewProgramModal(false);
      
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_add_program_loader(false);
    }

  }

  return (
    <>
     <Modal style={{minWidth:"1000px"}} open={addNewProgramModal} footer={false} onCancel={()=> setAddNewProgramModal(false)}>
    {add_program_loader ? (
      <div style={{height:"370px"}}>
      <SisLoader />
      </div>
    ) : (
      <>
      <div className="theme-content-head">
      <div className="theme-content-left-head">
      <h3>New Program Details</h3>
      </div>
      </div>
      <hr/>
        <div className="row">
          <div className="input-box col-6">
            <label>
              Program/Degree intended<i style={{ color: "red" }}>*</i>
            </label>
            <br></br>
            <Select
              placeholder="--Select Programme--"
              style={{ width: "100%" }}
              onChange={(value)=>program_change_function(value)}
              value={programme_intended}
            >
              {programme_list?.map((item) => {
                if (item.status === 1 && item.soft_delete === 0) {
                  return (
                    <>
                      <Select.Option value={item.id}>
                        {item.title}
                      </Select.Option>
                    </>
                  );
                }
              })}
            </Select>
            {errors?.programme_id && (
              <>
                <span style={{ color: "red" }}>
                  {errors?.programme_id[0]}
                </span>
              </>
            )}
          </div>
          <div className="input-box col-6">
            <label>
              Program Concentration
            </label>
            <br></br>
            <Select
              placeholder="--Select Programme--"
              style={{ width: "100%" }}
              onChange={(value) => {
                set_selected_program_concentration(value);
              }}
              disabled={program_concentration?.length === 0}
              value={
                selected_program_concentration === 0
                  ? ""
                  : selected_program_concentration
              }
            >
              {program_concentration &&
                program_concentration?.map((item) => {
                  if (item.status === 1 && item.soft_delete === 0) {
                    return (
                      <>
                        <Select.Option value={item.id}>
                          {item.title}
                        </Select.Option>
                      </>
                    );
                  }
                })}
            </Select>
            {errors?.program_concentration && (
              <>
                <span style={{ color: "red" }}>
                  {errors?.program_concentration[0]}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="input-box">
              <label>
                Student Type<i style={{ color: "red" }}>*</i>
              </label>
              <br></br>
              <Select
                placeholder="-- Student Type--"
                style={{ width: "100%"}}
                onChange={(value) => {
                  set_student_type(value);
                }}
                value={student_type}
              >
                {["New", "Transferred", "Returning", "Alumni"].map((item) => {
                  return (
                    <>
                      <Select.Option value={item}>{item}</Select.Option>
                    </>
                  );
                })}
              </Select>
              {errors?.student_type && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.student_type[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Academic Year<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Academic Year--"
                style={{ width: "100%" }}
                onChange={(value)=>calender_change_function(value)}
                value={
                  entering_year ? parseInt(entering_year) : entering_year
                }
                options={entering_year_list?.map((item) => ({
                  value: item.id,
                  calender_id: item.id,
                  label: item.title,
                }))}
              />
              {errors?.entering_year && (
                <>
                  <span style={{ color: "red" }}>
                    The academic year field is required.
                    {/* {errors?.academic_year[0]} */}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Matriculation Semester */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Matriculation Semester<i style={{ color: "red" }}>*</i>
              </label>
              <br />

              <Select
                placeholder="--Select Semester--"
                style={{ width: "100%" }}
                disabled={Boolean ? !entering_year : entering_year} // Disable until a academic year is selected
                onChange={(value) => {
                  set_matriculation_semester(value);
                }}
                value={matriculation_semester}
                options={sem_list?.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />

              {errors?.matriculation_semester && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.matriculation_semester[0]}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Matriculation Date */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Matriculation Date<i style={{ color: "red" }}>*</i>
              </label>
              <br />

              <DatePicker
                format={dateFormat}
                value={matriculation_date && dayjs(matriculation_date)}
                onChange={(date, dateString) =>
                  set_matriculation_date(dateString)
                }
                style={{ width: "100%" }}
              />

              {errors?.matriculation_date && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.matriculation_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Confer Date */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>Confer Date</label>
              <br />

              <DatePicker
                format={dateFormat}
                value={confer_date && dayjs(confer_date)}
                onChange={(date, dateString) => set_confer_date(dateString)}
                style={{ width: "100%" }}
              />
              {errors?.confer_date && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.confer_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Program Status */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Programme Status <i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                style={{ width: "100%" }}
                placeholder="Select Program Status"
                onChange={(value) => {
                  set_programme_status(value);
                }}
                value={programme_status}
                required
              >
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Inactive">Inactive</Select.Option>
                <Select.Option value="Completed">Completed</Select.Option>
                <Select.Option value="Withdraw">Withdraw</Select.Option>
                <Select.Option value="Dismissed">Dismissed</Select.Option>
                <Select.Option value="Suspended">Suspended</Select.Option>
                <Select.Option value="Probation">Probation</Select.Option>
                <Select.Option value="Deferred">Deferred</Select.Option>
                <Select.Option value="Readmitted">Readmitted</Select.Option>
                <Select.Option value="Leave of Absence">
                  Leave of Absence
                </Select.Option>
                <Select.Option value="Transferred Out">
                  Transferred Out
                </Select.Option>
                <Select.Option value="Discontinued">
                  Discontinued
                </Select.Option>
                <Select.Option value="Graduated with Honors">
                  Graduated with Honors
                </Select.Option>
                <Select.Option value="Non-Degree Seeking">
                  Non-Degree Seeking
                </Select.Option>
                <Select.Option value="Conditional Admission">
                  Conditional Admission
                </Select.Option>
              </Select>
              {errors?.programme_status && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.programme_status[0]}
                    {/* The program status field is required. */}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Entering Classification */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Entering Classification<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                onChange={(value) => {
                  set_entering_classification(value);
                }}
                value={entering_classification}
                style={{ width: "100%" }}
                placeholder="Select Entering Classifications"
                required
              >
                <Select.Option value="undergraduate">
                  Undergraduate
                </Select.Option>
                <Select.Option value="graduate">Graduate</Select.Option>
              </Select>
              {errors?.entering_classification && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.entering_classification[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Academic Standing */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Academic Standing
              </label>
              <br />
              <Select
                style={{ width: "100%" }}
                placeholder="Select Program Status"
                onChange={(value) => {
                  set_academic_standing(value);
                }}
                value={academic_standing}
                required
              >
                <Select.Option value="Good Standing">
                  Good Standing
                </Select.Option>
                <Select.Option value="Academic Warning">
                  Academic Warning
                </Select.Option>
                <Select.Option value="Academic Probation">
                  Academic Probation
                </Select.Option>
                <Select.Option value="Continued Probation">
                  Continued Probation
                </Select.Option>
                <Select.Option value="Academic Suspension">
                  Academic Suspension
                </Select.Option>
                <Select.Option value="Academic Dismissal">
                  Academic Dismissal
                </Select.Option>
                <Select.Option value="Conditional Admission">
                  Conditional Admission
                </Select.Option>
                <Select.Option value="Reinstated on Probation">
                  Reinstated on Probation
                </Select.Option>
                <Select.Option value="Dean’s List/Academic Honors">
                  Dean’s List/Academic Honors
                </Select.Option>
                <Select.Option value="Academic Progress Hold">
                  Academic Progress Hold
                </Select.Option>
                <Select.Option value="Disciplinary Suspension/Dismissal">
                  Disciplinary Suspension/Dismissal
                </Select.Option>
              </Select>
              {errors?.academic_standing && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.academic_standing[0]}
                    {/* The program status field is required. */}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
            <div className="input-box">
              {add_program_loader ? (
                <>
                  <Button type="primary" className="lusisbtn" style={{width:"210px"}}>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                      }
                    />{" "}
                    Add Program
                  </Button>
                </>
              ) : (
                <>
                  <Button className="lusisbtn" style={{width:"222px"}} type="primary" onClick={ADD_API}>
                    Add Program
                  </Button>
                </>
              )}
            </div>
      </>
    )}
     </Modal>
  </>
  )
}

export default AddNewProgramModal