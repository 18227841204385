import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import Login from './login/login';
import SisLayout from './pages/layout';
import Dashboard from './pages/dashboard';
import RoleList from './pages/roles/roleList';
import { useEffect, useState } from 'react';
import { CHECK_TOKEN } from './apis/apis';
import { Calendar, ConfigProvider, Spin } from 'antd';
import AddRole from './pages/roles/addRole';
import EditRole from './pages/roles/editRole';
import UsersList from './pages/users/usersList';
import AddUser from './pages/users/addUser';
import EditUser from './pages/users/editUser';
import CategoriesList from './pages/categories/categoriesList';
import AddCategory from './pages/categories/addCategory';
import EditCategory from './pages/categories/editCategory';
import CoursesList from './pages/courses/coursesList';
import AddCourse from './pages/courses/addCourse';
import EditCourse from './pages/courses/editCourse';
import SisProgrammeView from './pages/programs/SectionList';
import ProgrammeList from './pages/programs/programsList';
import CalendarList from './pages/calendar/calendars';
import AddCalendar from './pages/calendar/calendar-curd/addCalendar';
import EditCalendar from './pages/calendar/calendar-curd/editCalendar';
import SisProgrammeAdd from './pages/programs/component/SisProgrammeAdd';
import SisProgrammeEdit from './pages/programs/component/SisProgrammeEdit';
import AddSemester from './pages/calendar/semester/addSemester';
import EditSemester from './pages/calendar/semester/editSemester';


import EnrollmentsList from './pages/enrollments/enrollmentsList';
import AddEnrollment from './pages/enrollments/addEnrollment';
import EditEnrollment from './pages/enrollments/editEnrollment';

import FacultiesList from './pages/faculties/facultiesList';
import AddFaculty from './pages/faculties/addFaculty';
import EditFacult from './pages/faculties/editFaculty';


import RoomsList from './pages/rooms/roomsList';
import AddRoom from './pages/rooms/addRoom';
import EditRoom from './pages/rooms/editRoom';
import SemesterCourses from './pages/calendar/semester-course/SemesterCourses';
import EditStudent from './pages/students/EditStudent';
import StudentsList from './pages/students/StudentsList';
import AddStudent from './pages/students/AddStudent';
import EmergencyContact from './pages/students/EmergencyContact';
import StudentDocuments from './pages/students/StudentDocuments';
import SemesterRegistration from './pages/semester-registration/SemesterRegistration';
import SemesterRegistrationView from './pages/semester-registration/SemesterRegistrationView';
import SemesterRegistrationStudent from './pages/semester-registration/SemesterRegistrationStudent';

import SetPassword from './set_password/SetPassword';
import SetUserPassword from './set_password/SetUserPassword';
import CalendarSchedule from './pages/Calendar-Schedule/CalendarSchedule';
import EditStudentPopup from './pages/students-listing/listing/EditStudentPopup';
import SemesterList from './pages/calendar/semester/semesterList';
import Grading from './pages/gradess/Grading';
import Studentstally from './pages/students-listing/listing/Studentstally';
import StudentsDetails from './pages/students-listing/listing/Studentsdetails';
import Academic from './pages/students-listing/academic/Academic';
import Grade from './pages/students-listing/studentgrade/Studentgrade';
import Studentgrade from './pages/students-listing/studentgrade/Studentgrade';
import GradingCourses from './pages/gradess/GradingCourses';
import SisLoader from './widgets/loader';


import Attendancesetup from './pages/Attendance/attendanceSetup';
import RecordAttendanceList from './pages/Attendance/recordAttendanceList';

import StudentListHealthInsurance from './pages/Student-Services/studentListHealthInsurance';


import Billing from './pages/Billing/Billing';
import Genratebilling from './pages/Billing/Genratebilling';
import Paymentplan from './pages/Billing/paymentPlan';
import SectionListView from './pages/programs-view/SectionList';
import RecordAttendanceClass from './pages/Attendance/recordAttendanceClass';
import AttendanceCategory from './pages/Attendance/categories/AttendanceCategory';
import RecordAttendanceView from './pages/Attendance/recordAttendanceView';
import GradePlan from './pages/gradess/GradePlan';
import StudentAcademic from './pages/students-listing/academic/studentAcademics';
import StudentGradesView from './pages/students-listing/studentgrade/newstudentgrade/studentGradesView';
import AttendanceGenerateReport from './pages/Attendance/Attendance_Generate_Report/attendanceGenerateReport';
import DemographicReportVisualSection from './pages/Reports/BasicsReports/DemoGraphicReportVisualSection/demographicReportVisualSection';
import AcademicReportVisualSection from './pages/Reports/BasicsReports/AcademicReportVisualSection/academicReportVisualSection';
import AttendanceReportVisualSection from './pages/Reports/BasicsReports/AttendanceReportVisualSection/attendanceReportVisualSection';
import StudentOnlineApplicationList from './pages/Online-Application/studentOnlineApplicationList';
import EditStudentOnlineApplication from './pages/Online-Application/editStudentOnlineApplication';
import StudentsRequestsList from './pages/Students-Requests/studentsRequestsList';
import StudentRepeatCourseRequestDetail from './pages/Students-Requests/RepeatCourse/studentRepeatCourseRequestDetail';
import StudentDropCourseRequestDetail from './pages/Students-Requests/DropCourse/studentDropCourseRequestDetail';
import StudentWithdrawFromSemeterDetail from './pages/Students-Requests/WithdrawFromSemester/studentWithdrawFromSemesterDetail';
import StudentAddCourseRequestDetail from './pages/Students-Requests/AddCourse/studentAddCourseRequestDetail';
import AdvancedAcademicPerformanceReportVisualSection from './pages/Reports/AdvancedReports/AdvancedAcademicPerformanceReport/advancedAcademicPerformanceReportVisualSection';
import AdvancedEnrollandRegisReportVisualSection from './pages/Reports/AdvancedReports/AdvancedEnrollmentAndRegistrationReport/advancedEnrollandRegisReportVisualSection';
import AdvancedEnrollAppTrackReportVisualSection from './pages/Reports/AdvancedReports/AdvancedEnrollmentApplicationTrackingReport/advancedEnrollAppTrackReportVisualSection';
import AdvancedFinancialReportVisualSection from './pages/Reports/AdvancedReports/AdvancedFinancialReport/advancedFinancialReportVisualSection';
import AdvancedStdDemoReportVisualSection from './pages/Reports/AdvancedReports/AdvancedStudentDemographicsReport/advancedStdDemoReportVisualSection';
import SystemSettings from './pages/System-Settings/systemSettings';
import AdvancedAttendanceandRetentionReportVisualSection from './pages/Reports/AdvancedReports/AdvancedAttendanceAndRetentionReport/advancedAttendanceandRetentionReportVisualSection';
import StudentAcademicHistotyListing from './pages/students-listing/Academic-History/studentAcademicHistotyListing';
import StudentAcademicHistoryDetails from './pages/students-listing/Academic-History/studentAcademicHistoryDetails';
import AdvancedCourseSchedulingAndUtilizationReportVisualSection from './pages/Reports/AdvancedReports/AdvanceCourseSchedulingAndUtilizationReport/advancedCourseSchedulingAndUtilizationReportVisualSection';

// import Studentlistdetail from './pages/Attendance/Studentlistdetail';
// import Recordattendance from './pages/Attendance/Recordattendance';


function App() {

  return (
    <>
      <ConfigProvider
      theme={{
        token:{
          colorPrimary : "#DFA414"
        }
      }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SisLayout />}>
              <Route index element={<Dashboard />} />

              {/* Roles */}
              <Route path='/role-list' element={<RoleList />} />
              <Route path='/add-role' element={<AddRole />} />
              <Route path='/edit-role/:id' element={<EditRole />} />


              {/* UsersList */}
              <Route path='/users-list' element={<UsersList />} />
              <Route path='/add-user' element={<AddUser />} />
              <Route path='/edit-user/:id' element={<EditUser />} />

              {/* StudentsList */}
              <Route path='/registration-list' element={<StudentsList />} />
              <Route path='/add-registration' element={<AddStudent />} />
              <Route path='/edit-registration/:id' element={<EditStudent />} />
              <Route path='/emergency-contact/:id' element={<EmergencyContact />} />
              <Route path='/registration-documents/:id' element={<StudentDocuments />} />


              {/* CategoriesList */}
              <Route path='/categories-list' element={<CategoriesList />} />
              <Route path='/add-category' element={<AddCategory />} />
              <Route path='/edit-category/:id' element={<EditCategory />} />

              {/* CategoriesList */}
              <Route path='/courses-list' element={<CoursesList />} />
              <Route path='/add-course' element={<AddCourse />} />
              <Route path='/edit-course/:id' element={<EditCourse />} />

              {/* Programs  */}
              <Route path='/programmes-list' element={<ProgrammeList />} />
              <Route path='/add-programme' element={<SisProgrammeAdd />} />
              <Route path='/edit-programme/:id?' element={<SisProgrammeEdit />} />
              <Route path='/section/:id?' element={<SisProgrammeView />} />
              <Route path='/section-view/:id?' element={<SectionListView />} />
              {/* <Route path='/programmes-list' element={<ProgrammeList />} />
                  <Route path='/programme/:id?' element={<SisProgrammeView />} /> */}


              {/* Calendar  */}
              <Route path='/calendar-list' element={<CalendarList />} />
              <Route path='/calendar-schedule/:calendar_id' element={<CalendarSchedule />} />
              <Route path='/add-calendar' element={<AddCalendar />} />
              <Route path='/calendar-semester/:calendar_id' element={<SemesterList />} />
              <Route path='/edit-calendar/:id' element={<EditCalendar />} />

              {/* Calendar  */}
              <Route path='/add-semester/:id' element={<AddSemester />} />
              <Route path='/edit-semester/:id/:sem_id' element={<EditSemester />} />
              <Route path='/semester-courses/:id/:sem_id?' element={<SemesterCourses />} />

              {/* EnrollmentsList */}
              <Route path='/enrollments-list' element={<EnrollmentsList />} />
              <Route path='/add-enrollment/:id' element={<AddEnrollment />} />
              <Route path='/edit-enrollment/:id' element={<EditEnrollment />} />

              {/*  Faculty List */}
              <Route path='/facultiesList' element={<FacultiesList />} />
              <Route path='/add-faculty' element={<AddFaculty />} />
              <Route path='/edit-faculty/:id' element={<EditFacult />} />

              {/* RoomsList */}
              <Route path='/rooms-list' element={<RoomsList />} />
              <Route path='/add-room' element={<AddRoom />} />
              <Route path='/edit-room/:id' element={<EditRoom />} />


              {/* Semester Registration */}
              <Route path='/semester-registration' element={<SemesterRegistration />} />
              <Route path='/semester-registration-view/:id/:sem_id?' element={<SemesterRegistrationView />} />
              <Route path='/semester-registration-student/:id/:sem_id/:student_id' element={<SemesterRegistrationStudent />} />



              {/* grade Plan */}
              <Route path='/grade-plan' element={<GradePlan />} />
              <Route path='/grading' element={<Grading />} />
              <Route path='/grading-course/:semester_id/:course_id' element={<GradingCourses />} />


              {/* Students Listing */}

              <Route path='/students-list' element={<Studentstally />} />
              <Route path='/student-detail/:id' element={<StudentsDetails />} />
              <Route path='/edit-student-model/:id' element={<EditStudentPopup />} />
              <Route path='/student-academy/:id' element={<Academic />} />
              <Route path='/student-grades/:id' element={<Studentgrade />} />

              <Route path='/student-academic-detail/:id' element={<StudentAcademic />} />
              <Route path='/students-grade-detail/:id' element={<StudentGradesView/>} />
              <Route path='/students-academic-history/:id' element={<StudentAcademicHistotyListing/>} />
              <Route path='/students-academic-history-details/:id/:program_row_id' element={<StudentAcademicHistoryDetails/>} />
              <Route path='/record-attendance-students-listing' element={<RecordAttendanceList />} />


              
            {/* student services */}
            <Route path='/health-insurance' element={<StudentListHealthInsurance />} />


              {/* billing module */}
              <Route path='/billing' element={<Billing />} />
              <Route path='/genrate-billing/:acad_calendar_id/:sem_id/:id' element={<Genratebilling />} />
              <Route path='/payment-plan' element={<Paymentplan />} />

              {/* Students Requests */}
              <Route path='/students-requests-list' element={<StudentsRequestsList />} />
              <Route path='/student-repeat-request-detail/:std_id/:c_id/:sem_id' element={<StudentRepeatCourseRequestDetail />} />
              <Route path='/student-drop-request-detail/:std_id/:c_id/:sem_id' element={<StudentDropCourseRequestDetail />} />
              <Route path='/student-withdraw-request-detail/:std_id/:sem_id' element={<StudentWithdrawFromSemeterDetail />} />
              <Route path='/student-add-request-detail/:std_id/:sem_id' element={<StudentAddCourseRequestDetail />} />

              {/* Attendance Module */}
              <Route path='/attendence-setup' element={< Attendancesetup />} />
              <Route path='/record-attendance-class' element={<RecordAttendanceClass />} />
              <Route path='/record-attendance-list/:faculty_id/:course_id/:course_code/:course_title/:semester_id/:timing/:start_time/:end_time/:faculty_name/:honorific/:selected_date' element={<RecordAttendanceView />} />
              <Route path='/attendance-category/:id?' element={<AttendanceCategory />} />
              <Route path='/attendance-report' element={<AttendanceGenerateReport />} />
              
              {/* Report Module */}

              <Route path='/academic-report-section' element={<AcademicReportVisualSection/>}/>
              <Route  path='/demographic-report-section' element={<DemographicReportVisualSection/>}/>
              <Route path='/attendance-report-section' element={<AttendanceReportVisualSection/>}/>
              <Route path='/advanced-academic-performance-report-section' element={<AdvancedAcademicPerformanceReportVisualSection/>}/>
              <Route path='/advanced-enrollment-registration-report-section' element={<AdvancedEnrollandRegisReportVisualSection/>}/>
              <Route path='/advanced-enrollment-application-Tracking-report-section' element={<AdvancedEnrollAppTrackReportVisualSection/>}/>
              <Route path='/advanced-financial-report-section' element={<AdvancedFinancialReportVisualSection/>} />
              <Route path='/advanced-student-demographics-report-section' element={<AdvancedStdDemoReportVisualSection/>} />
              <Route path='/advanced-attendance-retention-report-section' element={<AdvancedAttendanceandRetentionReportVisualSection/>} />
              <Route path='/advanced-course-scheduling-and-utilization-report-section' element={<AdvancedCourseSchedulingAndUtilizationReportVisualSection/>} />

              {/* Online Application */}
              <Route path='/student-online-application-list' element={<StudentOnlineApplicationList/>}/>
              <Route path='/edit-student-online-application/:id' element={<EditStudentOnlineApplication />} />

              {/* System Settings */}
              <Route path='/system-setting' element={<SystemSettings/>} />



            </Route>
            <Route path="/login/:token" element={<Login />} />
            <Route path='/set-password/:token' element={<SetPassword />} />
            <Route path='/set-user-password/:token' element={<SetUserPassword />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </>

  );
}

export default App;
