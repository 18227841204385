import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Spin, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SIS_STORAGE, AGREEMENT_SAVE, GET_AGREEMENT } from "../../apis/apis";
import SisLoader from "../../widgets/loader";


const Agreement = ({id}) => {

    const [agreement_check, set_agreement_check] = useState("");
    const [student_signature_ref, set_student_signature_ref] = useState("");
    const [student_signature, set_student_signature] = useState("");
    const [api_student_signature, set_api_student_signature] = useState("");
    const [student_signature_empty_error, set_student_signature_empty_error] = useState("");
    const [agreement_save_status, set_agreement_save_status] = useState("");
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
         VIEW_AGREEMENT();
    }, []);


    const VIEW_AGREEMENT = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', atob(id));
        const VIEW_AGREEMENT_API_RESPONSE = await GET_AGREEMENT(FORM_DATA);
        if (VIEW_AGREEMENT_API_RESPONSE?.data?.status) {
            set_loader(false);
            localStorage.setItem('agreement_information_status', VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_save_status);
            set_agreement_save_status(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_save_status);
            set_agreement_check(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.agreement_check);
            set_api_student_signature(VIEW_AGREEMENT_API_RESPONSE?.data?.student_signature?.student_signature);
        }
        else {
            set_loader(false);
        }
    }

    const AGREEMENT_DRAFT_NEXT = async (agreement_save_status) => {
        set_loader(true);
        set_errors("");
        set_student_signature_empty_error("")
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', atob(id));
        FORM_DATA.append('agreement_save_status', agreement_save_status);
        FORM_DATA.append('agreement_check', (agreement_check === undefined) || (agreement_check === null) || (agreement_check === 0) ? "" : agreement_check);
        FORM_DATA.append('student_signature', (student_signature === null) || (student_signature === "") ? api_student_signature : student_signature);
        const API_RESPONSE = await AGREEMENT_SAVE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {

            if (API_RESPONSE?.data?.agreement_save_status == "1") {
                notification.open({
                    message: "Success!!",
                    description: "Agreement Save as Draft.",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                set_loader(false);
                VIEW_AGREEMENT();
            }
            if (API_RESPONSE?.data?.agreement_save_status == "2") {
                notification.open({
                    message: "Success!!",
                    description: "Agreement Saved Sucessfully",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                set_loader(false);
                navigate('/formpreview');
            }
        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false);
        }
    }

    return (
        <>
            {loader ? <SisLoader />
                : <>
                    <div>
                        <br />
                        <hr />
                        <br />
                        <div className="theme-content-left-head">
                            <h3>Agreement</h3>
                        </div>
                        <br />
                    </div>
                    <p>I agree with Lincoln University’s application deadlines and admission requirements. I agree to the release of all transcripts and test scores from my previous education.</p><br />
                    <p>LI certify that the information is complete and accurate. I understand that making false statements will result in disciplinary actions, denial of admission and/or invalidation of credits or degrees earned. If admitted, I agree to abide by the policies, rules, and regulations of Lincoln University. If any information changes prior to my entry into the university, I will notify the Office of Admissions.</p><br />
                    <p>I understand that my application will not be processed without submitting of a non-refundable application fee.</p><br />

                    <div className="row" style={{ marginBottom: "0" }}>
                        <div className="col-6">
                            <div className="input-box" style={{ position: "relative" }}>
                                <label style={{ textTransform: "none" }}>
                                    Do you understand and agree to the terms listed above?<i style={{ color: "red" }}>*</i>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div style={{ marginLeft: "10px" }}>
                            <Checkbox
                                disabled={true}
                                checked={agreement_check}
                                onChange={(e) => e.target.checked ? set_agreement_check(1) : set_agreement_check(0)}
                                style={{ color: "#626262", fontWeight: "500", textTransform: "none" }}>Yes, I understand and agree to the terms listed above.</Checkbox>
                            <br />
                            {errors?.agreement_check && <><span style={{ color: "red" }}><br />
                                {errors?.agreement_check[0]}</span></>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="input-box">
                                <label>
                                    Signature<i style={{ color: "red" }}>*</i>
                                </label>
                                {api_student_signature &&
                                    <div style={{ border: '1px solid', padding: "10px" }}>
                                        <img src={api_student_signature} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* button */}
                    {/* <div className="row">
                        <div className="col-2">
                            <div className="input-box">
                                {loader ? (
                                    <>
                                        <Button type="primary">
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                }
                                            />{" "}
                                            Saving
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                             onClick={() => { AGREEMENT_DRAFT_NEXT(2) }}
                                          type="primary">
                                            Save
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div> */}
                </>
            }
        </>
    );

}

export default Agreement












