import { Input, Select } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Tooltip, Pagination } from "antd";
import ForOfficeUse from "./forOfficeUse";
import PersonalInformation from "./personalInformation";
import DemographicInformation from "./demographicInformation";
import FinanceDeclaration from "./financeDeclaration";
import Agreement from "./agreement";
import OnlineFeePayment from "./OnlineFeePayment";
import { BACKEND_URL } from "../../config/config";



const EditStudentOnlineApplication = () => {
  const navigate = useNavigate();

  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [resgistration_payment_props, set_resgistration_payment_props] = useState('');
  const { id } = useParams();
    return(
        <>

      <ForOfficeUse id={id} resgistration_payment_props={resgistration_payment_props} />
    
      <div style={{padding:"30px 20px", border: "1px solid #E1E1E1",borderRadius:"15px"}}>
      <PersonalInformation id={id}/>
      <DemographicInformation id={id}/>
      <FinanceDeclaration id={id}/>
      <Agreement id={id}/>
      <OnlineFeePayment id={id} set_resgistration_payment_props={set_resgistration_payment_props}/>
      </div>
        </>
    );
}

export default EditStudentOnlineApplication;