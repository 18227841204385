import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Spin, Tree, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CourseContainer from '../CourseContainer';
import SubSectionContainer from '../SubSectionContainer';
import { DeleteOutlined, DownOutlined, LoadingOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import SisLoader from '../../../../widgets/loader';
import { ADD_SECTION, ADD_SUBSECTION, DELETE_SECTION, DELETE_SUBSECTION, EDIT_SUBSECTION, SUBSECTIONS_LIST, UPDATE_SECTION, UPDATE_SECTION2, UPDATE_SUBSECTION } from '../../../../apis/apis';
import { useNavigate } from 'react-router-dom';
import CourseContainerView from '../CourseContainer';

const EditSubSectionView = (props) => {
    const navigate = useNavigate();
    const {
        section_item,
        sub_section_id,
        programme_id,
        set_sub_page_loader,
        section_id,
        set_show_sub_section,
        sec_title,
        sec_dec,
        sec_unit
    } = props;

    const editorRef = useRef(null);
    const [page_loader, set_page_loader] = useState(false)
    const [show_form, set_show_form] = useState(false)

    const [sub_section, set_sub_section] = useState([]);

    const [title, set_title] = useState('');
    const [courses, set_courses] = useState([]);
    const [unit, set_unit] = useState('');
    const [description, set_description] = useState('');
    const [show_courses, set_show_courses] = useState(false);
    const [errors, set_errors] = useState([]);


    // Button loader
    const [button_loader, set_button_loader] = useState(false);
    const UPDATE_API2 = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', section_id);
        FORM_DATA.append('title', sec_title);
        FORM_DATA.append('description', sec_dec);
        FORM_DATA.append('units', sec_unit);
        FORM_DATA.append('programme_id', programme_id);
        const API_RESPONSE = await UPDATE_SECTION2(FORM_DATA);
        set_button_loader(false)
    }
    const UPDATE_API = async () => {
        set_button_loader(true)
        UPDATE_API2()
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', sub_section_id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('parent', section_id);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('course_status', 0);
        const API_RESPONSE = await UPDATE_SUBSECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_show_sub_section(false)
            set_sub_page_loader(true)
            notification.open({
                message: 'Success!!',
                description: 'Programme sub section Successfully updated.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });

        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }


    }
    const EDIT_SUBSECTION_VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', sub_section_id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_ROLE_API_RESPONSE = await EDIT_SUBSECTION(FORM_DATA);
        const get_sec = EDIT_ROLE_API_RESPONSE?.data?.section;
        set_title(get_sec.title);
        set_unit(get_sec.units);
        set_description(get_sec.description)
        set_courses(EDIT_ROLE_API_RESPONSE?.data?.section?.courses);
        set_sub_page_loader(false);
    }
    const DELETE_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', sub_section_id);
        const API_RESPONSE = await DELETE_SUBSECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            navigate('/section/' + btoa(programme_id))

        }

    }
    // useEffect(() => {
    //     VIEW_API();

    // }, [])
    return (
        <>
            <div className='sis-section' style={{ backgroundColor: "#fff" }}>
                <div className='sis-section-head'>
                    <h4> {title ? title : section_item.title}</h4>
                    <div>
                        {/* <b onClick={DELETE_API}><DeleteOutlined /></b> */}
                        {show_form ? <b onClick={() => set_show_form(false)}><UpOutlined /> </b> : <b onClick={() => {set_show_form(true);EDIT_SUBSECTION_VIEW_API()}}> <DownOutlined /> </b>}
                    </div>
                </div>


                {show_form && <>
                    <div className='sis-section-container'>
                        <div className='input-box'>
                            <label htmlFor="name">Section Title</label>
                            <p>{title}</p>
                            {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="name">Section  Description</label>
                            <p dangerouslySetInnerHTML={{__html:description}}></p>
                            {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="Units">Section Units</label>
                            <p>{unit}</p>
                        </div>

                        <CourseContainerView set_courses={set_courses} courses={courses} sec_id={section_item?.parent} sub_sec_id={section_item?.id}/>
                        {errors?.courses && <><span style={{ color: "red" }}>{errors?.courses[0]}</span></>}
                   

                    </div>
                </>}
            </div>

        </>
    );
};

export default EditSubSectionView;