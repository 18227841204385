import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import React, { useState } from 'react'

const PreviousGradeTable = ({ transferred_course_item, university_course_item }) => {

    const [transferred_course_list, set_Transferred_Course_List] = useState(transferred_course_item);
    const [university_course_list, set_University_Course_List] = useState(university_course_item);
    const transferred_course_column = [
        {
            title: "Semester",
            width: '12%',
            render: (text, record) => {
                return <><span>{record?.transferred_semester}</span></>;
            },
            // sorter: (a, b) => a.transferred_semester.localeCompare(b.transferred_semester),
        },
        {
            title: "University",
            width: '12%',
            render: (text, record) => {
                return <><span>{record?.transferred_institution}</span></>;
            },
            // sorter: (a, b) => a.transferred_institution.localeCompare(b.transferred_institution),
        },
        {
            title: "Courses",
            width: '40%',
            render: (text, record) => {
                return <><span>{record.transferred_course}{" "}(TR)</span></>;
            },
            sorter: (a, b) => a.transferred_course.localeCompare(b.transferred_course),
        },
        {
            title: "Course Code",
            width: '12%',
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
        },

        {
            title: "Attempt Credit",
            width: '12%',
            render: (text, record) => {
                return <><span >{record?.credit_attempted.toFixed(1)}</span></>;
            },
        },
        {
            title: "Earned Credits",
            width: '12%',
            render: (text, record) => {
                return <><span >{record?.credit_earn.toFixed(1)}</span></>;
            },
        },
        {
            title: "Grade",
            width: '12%',
            render: (text, record) => {
                return <>
                    <span>CR</span>
                </>;
            },
        },
    ];

    const university_course_column = [
        {
            title: "Courses",
            width: '40%',
            render: (text, record) => {
                return <>
                    {record.perquisite_courses == null || record.perquisite_courses == "null" ?
                        <span>{record.course_title}</span>
                        : <span>{record.course_title}{" "}<span className='perquisite-courses'>P</span></span>}
                </>;
            },
            sorter: (a, b) => a.course_title.localeCompare(b.course_title),

        },
        {
            title: "Course Code",
            width: '15%',
            render: (text, record) => {
                return <><span>{record?.course_code}</span></>;
            },
        },
        {
            title: "Attempt Credit",
            width: '15%',
            render: (text, record) => {
                return <><span>{record?.given_credits.toFixed(1)}</span></>;
            },
        },
        {
            title: "Earned Credits",
            width: '15%',
            render: (text, record) => {
                return <><span>{record?.credit_earn.toFixed(1)}</span></>;
            },
        },
        {
            title: "Grade",
            width: '15%',
            render: (text, record) => {
                return <>
                {record?.student_grade === 'R' ? (
                    <Tooltip
                    color="#fff"
                     title={<div><h4 className='first-letter-capitalize'>Rpeated Course</h4>
                        <p>Previous grade was {record?.cgpa_manage_course_request?.old_grade} for {record?.course_code} {record.course_title} before the student repeated the course.</p>
                    </div>}>
                        <span>{record?.student_grade}{" "}
                            <InfoCircleOutlined style={{ marginLeft: 5, color:"#900000" }} />
                        </span>
                    </Tooltip>
                ) : (
                    <span>{record?.student_grade}</span>
                )}
            </>

            },
        },
    ];

  return (
    <>
    <br></br>
    {
        transferred_course_list?.length > 0 ? <>
            <Table columns={transferred_course_column} dataSource={transferred_course_list} pagination={false}
                summary={(pageData) => {
                    let totalAttemptedCredits = 0;
                    let totalEarnedCredits = 0;

                    pageData.forEach(({ credit_attempted, credit_earn }) => {
                        totalAttemptedCredits += credit_attempted;
                        totalEarnedCredits += credit_earn;
                    });

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <div></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <div></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <div><strong>Total</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <div><strong>{totalAttemptedCredits.toFixed(1)}</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <div><strong>{totalEarnedCredits.toFixed(1)}</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                <div></div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }} />
            <br />
        </> : <></>
    }
    {
        university_course_list?.length > 0 ? <>
            <Table columns={university_course_column} dataSource={university_course_list} pagination={false}
                summary={(pageData) => {
                    let totalAttemptedCredits = 0;
                    let totalEarnedCredits = 0;

                    pageData.forEach(({ given_credits, credit_earn, student_grade }) => {
                        if (student_grade != "-") {

                            totalAttemptedCredits += given_credits;
                            totalEarnedCredits += credit_earn;
                        }

                    });
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <div></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div><strong>Total</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <div><strong>{totalAttemptedCredits.toFixed(1)}</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <div><strong>{totalEarnedCredits.toFixed(1)}</strong></div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <div></div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }} />
        </> : <></>
    }
</>
  )
}

export default PreviousGradeTable;