import React, { useRef } from 'react'
import { useEffect, useState } from "react";
import { Checkbox, Input, Pagination, Table, notification, } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { CALENDARS_LIST, LIST_STUDENT_HEALTH_INSURANCE, STUDENT_HEALTH_INSURANCE_LISTING_SEARCH, STUDENT_SERVICE_ADD_UPDATE, } from "../../apis/apis";
import SisLoader from '../../widgets/loader';
import {useNavigate} from "react-router-dom";
import HealthInsuranceComment from './healthInsuranceComment';


function StudentListHealthInsurance() {

  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [calendars_list, set_calendars_list] = useState([]);
  const [semesters_list, set_semesters_list] = useState([]);
  const [active_calender_id, set_active_calender_id] = useState();
  const [active_sem_id, set_active_sem_id] = useState();
  const [comment, set_comment_data] = useState("");
  const [table_list, set_table_list] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [checkbox_disabled, set_checkbox_disabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, set_last_page] = useState(1);
  const [total, set_total] = useState("");
  const [per_page, set_per_page] = useState("");
  const [search_error, set_search_error] = useState("")
  const debounceTimeout = useRef(null);
  const errorTimeoutRef = useRef(null);
  

  // Permissions state manage 
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  
  const debounce = (callback, delay) => {
    return (...args) => {
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

  const handleSearch = debounce((query) => {
    if(query != "")
    {
      SEARCH_LIST_API(query);
    }
    else{
      REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,currentPage)
    }
  }, 800);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    if (value === "") {
      set_search_error("");
      handleSearch(value);
      return;
    }
    if (value.length < 3) {
      errorTimeoutRef.current = setTimeout(() => {
      set_search_error("Please enter at least 3 characters.")
    }, 800);
      return;
    }
    set_search_error("");
    handleSearch(value);
  };


  const SEARCH_LIST_API = async (UserQuery) => {
    set_loader(true)
   const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
   const FORM_DATA = new FormData();
   FORM_DATA.append("token", USER_DATA.token);
   FORM_DATA.append("acad_calender_id", active_calender_id);
   FORM_DATA.append("semester_id", active_sem_id);
   FORM_DATA.append("search", UserQuery);
   const API_RESPONSE = await STUDENT_HEALTH_INSURANCE_LISTING_SEARCH(FORM_DATA);
   if (API_RESPONSE?.data?.status) {
     set_table_list(API_RESPONSE?.data?.student_list_health_insurance?.data);
     setFilteredData(API_RESPONSE?.data?.student_list_health_insurance?.data);
     setCurrentPage(API_RESPONSE?.data?.student_list_health_insurance?.current_page);
     set_last_page(API_RESPONSE?.data?.student_list_health_insurance?.last_page);
     set_total(API_RESPONSE?.data?.student_list_health_insurance?.total);
     set_per_page(API_RESPONSE?.data?.student_list_health_insurance?.per_page);
     set_loader(false);
   } else {
     set_loader(false);
   }
 };

  const ADD_API = async (health_insurance_status, comment, student_id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", active_calender_id);
    FORM_DATA.append("semester_id", active_sem_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("student_status", "Ready for payment");
    FORM_DATA.append("table_list", JSON.stringify(table_list));
    FORM_DATA.append("health_insurance_status", health_insurance_status);
    FORM_DATA.append("comment", (comment == null || comment == "null") ? "" : comment) ;

    const API_RESPONSE = await STUDENT_SERVICE_ADD_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Semester Health Insurance update successfully .",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      if(searchQuery)
        {
          SEARCH_LIST_API(searchQuery);
        }
        else{
          REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,currentPage)
        }
      // REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,currentPage);
      // navigate('/health-insurance/' + calender_id + '/' + sem_id)
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const Calendar_API_RESPONSE = await CALENDARS_LIST(FORM_DATA);

    if (Calendar_API_RESPONSE?.data?.status) {
      // set_loader(false);
      set_calendars_list(Calendar_API_RESPONSE?.data?.calendars);
      set_active_calender_id(Calendar_API_RESPONSE?.data?.calendars[0]?.id)
      set_semesters_list(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters)
      set_active_sem_id(Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id);
      if(Calendar_API_RESPONSE?.data?.calendars[0]?.id && Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id)
      {
        REGISTER_STUDENT_LIST_API(Calendar_API_RESPONSE?.data?.calendars[0]?.id,Calendar_API_RESPONSE?.data?.calendars[0]?.semesters[0]?.id,currentPage);
      }

    } else {
      // set_loader(false);
    }
  }

  const REGISTER_STUDENT_LIST_API = async (calender_id,sem_id,page) => {
     set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", calender_id);
    FORM_DATA.append("semester_id", sem_id);
    FORM_DATA.append("page", page);
    const API_RESPONSE = await LIST_STUDENT_HEALTH_INSURANCE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE?.data?.student_list_health_insurance?.data);
      setFilteredData(API_RESPONSE?.data?.student_list_health_insurance?.data);
      setCurrentPage(API_RESPONSE?.data?.student_list_health_insurance?.current_page);
      set_last_page(API_RESPONSE?.data?.student_list_health_insurance?.last_page);
      set_total(API_RESPONSE?.data?.student_list_health_insurance?.total);
      set_per_page(API_RESPONSE?.data?.student_list_health_insurance?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
  }, []);

  

  const columns = [
    {
      title: "ID",
      render:(text, record) => {
        return(
          <>
           {record.enrollment_details.id}
          </>
        )
      },
      sorter: (a, b) => a?.enrollment_details.id - b?.enrollment_details.id,
    },
    {
      title: "Student ID",
      render:(text, record) => {
        return(
          <>
           {record.old_student_id ? record.old_student_id : record.enrollment_details.new_student_id}
          </>
        )
      },
      // sorter: (a, b) => a?.old_student_id - b?.old_student_id,
      sorter: (a, b) => {
        const idA = a.old_student_id || a.enrollment_details?.new_student_id || '';
        const idB = b.old_student_id || b.enrollment_details?.new_student_id || '';
        return idA.localeCompare(idB);
      },
    },
    {
      title: "Name",
      render: (text, record) => {
        if (record.student_status) {
          return (
            <>
              <span>
                {record.enrollment_details.first_name + ' '}
                {(record.enrollment_details.middle_name === 'null' || record.enrollment_details.middle_name === null) ? '' : record.enrollment_details.middle_name + ' '}
                {record.enrollment_details.last_name}
              </span>
            </>

          );
        } else {
          return (
            <>
              <span
                style={{ cursor: "pointer" }}

              >
                {record.enrollment_details.first_name}
                {(record.enrollment_details.middle_name === 'null' || record.enrollment_details.middle_name === null) ? ' ' : record.enrollment_details.middle_name}
                {record.enrollment_details.last_name}
                <span
                  style={{
                    backgroundColor: "red",
                    fontSize: "10px",
                    position: "relative",
                    color: "#fff",
                    padding: "2px 5px",
                    top: "-6px",
                    left: "3px",
                    borderRadius: "5px",
                  }}
                >
                  New
                </span>
              </span>
            </>
          );
        }
      },
    },

    {
      title: "Program Intended",
      dataIndex: "program_intended",
      key: "title",
      render: (text, record) => {
        return <> {record?.enrollment_details?.title}</>;

      },
    },

    {
      title: "Student Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {

        return <> {(record?.enrollment_details?.student_status == 0 && 'Ready for Payment')||
        (record?.enrollment_details?.student_status == 2 && 'Paid')||
        (record?.enrollment_details?.student_status == 3 && 'Partial Paid')||
        (record?.enrollment_details?.student_status == 4 && 'Not Paid')
        }</>;

      },
    },

    {
      title: "Comment (if any)",
      // dataIndex: "primary_email",
      // key: "primary_email",
      render: (text, record) => {

        // const handleKeyDown = (e) => {
        //   if (e.key === "Enter") {
        //     const commentData = e.target.value;
        //     ADD_API(record?.enrollment_details?.health_insurance_status,commentData,record.student_id);
        //   }
        // }

        return <>
          {(check_permissions('student_semester_health_insurance', 'create') || check_permissions('student_semester_health_insurance', 'update')) &&
          <HealthInsuranceComment
          item={record}
          active_calender_id={active_calender_id}
          active_sem_id={active_sem_id}
          searchQuery={searchQuery}
          currentPage={currentPage}
          SEARCH_LIST_API={SEARCH_LIST_API}
          REGISTER_STUDENT_LIST_API={REGISTER_STUDENT_LIST_API}
          table_list={table_list}
          />
            // <Input
            //   defaultValue={record?.enrollment_details?.comment}
            //   placeholder=" comment....."
            //   onChange={(e) => set_comment_data(e.target.value)}
            //   onKeyDown={handleKeyDown}
            // />
            
          }
        </>;
      },
    },

    {
      title: "Health Insurance",
      dataIndex: "health_insurance_status",
      key: "health_insurance_status",

      render: (text, record) => {
       const paid_disabled =  record.enrollment_details.student_status == 2 || record.enrollment_details.student_status == 3;
        return <>
          {(check_permissions('student_semester_health_insurance', 'create') || check_permissions('student_semester_health_insurance', 'update')) &&
            <>
              {record?.enrollment_details?.health_insurance_status ? <>
                <Checkbox
                disabled={paid_disabled}
                  checked
                  onChange={(e) => {
                    ADD_API(e.target.checked ? 1 : 0, record?.enrollment_details?.comment, record.student_id);

                  }}
                />
              </> : <>
                <Checkbox
                 disabled={paid_disabled}
                  onChange={(e) => {
                    ADD_API(e.target.checked ? 1 : 0, record?.enrollment_details?.comment, record.student_id);

                  }}
                />
              </>}
            </>
          }
        </>;

      },
    },


  ];

  const generate_sem_list = (item) => {
    setSearchQuery("");
     set_search_error("");
    set_semesters_list(item?.semesters);
    set_active_calender_id(item?.id);
    set_active_sem_id(item.semesters[0]?.id);
    REGISTER_STUDENT_LIST_API(item?.id,item.semesters[0]?.id,1);
  }

  const generate_students_list = (sem_detail) => {
    setSearchQuery("");
    set_search_error("");
    set_active_sem_id(sem_detail?.id);
    REGISTER_STUDENT_LIST_API(sem_detail?.calendar_id,sem_detail?.id,1);
  }

  const pagination_changes  = (UserSearchPage) => {
    setCurrentPage(UserSearchPage);
    if(searchQuery == "")
      {
    REGISTER_STUDENT_LIST_API(active_calender_id,active_sem_id,UserSearchPage);
      }
      else{
        SEARCH_LIST_API(searchQuery)
      }
};

  return (
    <>
    {loader ? (
            <SisLoader />
          ) : (
            <>
      <div className="theme-content-left-head">
        <h3>Student Services</h3>
      </div>
      <div className="theme-content-head">
        <div className="sis-tab-menu">
          {calendars_list.length > 0 && (
            <>
              {calendars_list.map((item, index) => (
                <>
                  <>
                    {(active_calender_id == item?.id) ?
                      <>
                        <span className="active" >{item?.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_sem_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          )}
        </div>
       
      </div>
      <div className="sis-tab-sem-menu">
          {semesters_list?.length > 0 &&
            <>
              {semesters_list?.map((item, index) => (
                <>
                  <>
                    {(active_sem_id == item.id) ?
                      <>
                        <span className="active" >{item.title} </span>
                      </>
                      :
                      <>
                        <span onClick={() => generate_students_list(item)} >{item.title}</span>
                      </>
                    }
                  </>
                </>
              ))}
            </>
          }
        </div>
      <div>
        <div className="input-box" style={{ maxWidth: "250px", marginTop: "30px" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleInputChange}
          />
         {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
        </div>
        <div className="sis-tab-content" style={{ marginTop: "50px" }}  >
        <Table columns={columns} dataSource={filteredData} pagination={false} />
        <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={parseInt(currentPage)} total={parseInt(total)} pageSize={parseInt(per_page)} /></div>
   
        </div>
      </div>
      </>
    )}
    </>
  )
}


export default StudentListHealthInsurance