import React, { useEffect, useState } from "react";
import SisLoader from "../../../widgets/loader";
import { useParams } from "react-router-dom";
import maleAvatar from "../../../images/user.png";
import { SIS_STORAGE, sisDateFormat, STUDENT_ACADEMIC_HISTORY_DETAILS } from "../../../apis/apis";
import PreviousSemesterWiseGradeView from "./previousSemesterWiseGradeView";

const StudentAcademicHistoryDetails = () => {
  const [loader, set_loader] = useState(false);
  const[table_list, set_table_list] = useState([]);
  const[basic_information, set_basic_information] = useState([]);
  const { id, program_row_id } = useParams();

    const VIEW_STUDENT_ACADEMIC_HISTORY_LIST = async() => {
      set_loader(true)
      const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
      const FORM_DATA = new FormData();
      FORM_DATA.append('token', USER_DATA.token);
      FORM_DATA.append('student_id', atob(id));
      FORM_DATA.append('programme_id', atob(program_row_id));
      const API_RESPONSE = await STUDENT_ACADEMIC_HISTORY_DETAILS(FORM_DATA);
      set_loader(false);
      if(API_RESPONSE?.data?.status)
      {
        set_basic_information(API_RESPONSE?.data?.basic_information);
        set_table_list(API_RESPONSE?.data?.tables);
        set_loader(false);
      }
    }
   useEffect(() => {
    VIEW_STUDENT_ACADEMIC_HISTORY_LIST();
      }, []);
  

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="row">
            <div
              className="col-2"
              style={{ maxWidth: "100px", minWidth: "100px" }}
            >
              <img
                style={{
                  width: "78px",
                  height: "78px",
                  borderRadius: "14px",
                  marginleft: "-12px",
                }}
                src={
                  basic_information?.enrollment_image
                    ? `${SIS_STORAGE}/enrollments/${basic_information.enrollment_image}`
                    :
                  maleAvatar
                }
                alt="Profile"
              />
            </div>
            <div className="col-8">
              <h3
                style={{
                  marginBottom: "10px",
                  width: "260px",
                  fontFamily: "roboto",
                  fontSize: "24px",
                  fontWeight: "401",
                }}
              >
                {basic_information?.first_name}{" "}
                  {basic_information?.middle_name !== "null" &&
                    basic_information?.middle_name &&
                    basic_information?.middle_name}{" "}
                  {basic_information?.last_name}{" "}
              </h3>
              <div className="row">
                <div className="col-3">
                  <p>Application number</p>
                  <h4 style={{ marginTop: "6px" }}>
                    {basic_information?.application_no ? basic_information?.application_no : "-"}
                  </h4>
                </div>
                <div className="col-3">
                  <p>Student ID</p>
                  <h4 style={{ marginTop: "6px" }}>
                    {basic_information?.student_id ? basic_information?.student_id : "-"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="row">
            <div className="col-3">
              <p>Student Type</p>
              <h4 style={{ marginTop: "6px" }}>
                {basic_information?.student_type ? basic_information?.student_type : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Programme/Degree</p>
              <h4 style={{ marginTop: "6px" }}>
                {basic_information?.programme_title ? basic_information?.programme_title : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Programme Concentration</p>
              <h4 style={{ marginTop: "6px" }}>
                {basic_information?.program_concentration ? basic_information?.program_concentration : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Academic Year</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.entering_year_title ? basic_information?.entering_year_title : "-"}
              </h4>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-3">
              <p>Matriculation Semester</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.matriculation_semester_title ? basic_information?.matriculation_semester_title : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Matriculation Date</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.matriculation_date ? sisDateFormat(basic_information?.matriculation_date) : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Confer Date</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.confer_date ? sisDateFormat(basic_information?.confer_date) : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Academic Standing</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.academic_standing ? basic_information?.academic_standing : "-"}
              </h4>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-3">
              <p>Entering Classification</p>
              <h4 style={{ marginTop: "6px" }}>
               {basic_information?.entering_classification ? basic_information?.entering_classification : "-"}
              </h4>
            </div>
            <div className="col-3">
              <p>Program Status</p>
              <h4 style={{ marginTop: "6px" }}>
              {basic_information?.programme_status ? basic_information?.programme_status : "-"}
              </h4>
            </div>
          </div>
          <br/>
          <hr/>
          <br/>
          {table_list?.length > 0 ? <>
                    {table_list?.map((item) => (
                     <>
                    {/* <br/> */}
                    <PreviousSemesterWiseGradeView key={item} item={item} />
                    <br/>
                    </>
                    ))}
                </> : <>
                    <p style={{ margin: "50px 0", textAlign: "center", color: "red", fontSize: "14px" }}>Student Grades Data Empty.</p>
                </>

                }
        </>
      )}
    </>
  );
};

export default StudentAcademicHistoryDetails;
